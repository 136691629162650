<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.583"
    height="31.289"
    viewBox="0 0 31.583 31.289"
  >
    <g
      id="logo-mobile"
      transform="translate(-3022.066 6904.861)"
    >
      <g
        id="Merged"
        transform="translate(3022.066 -6904.861)"
      >
        <path
          id="Subtraction_16"
          data-name="Subtraction 16"
          d="M12.9,28.04a1.355,1.355,0,0,1-.712-.2L.712,20.773A1.538,1.538,0,0,1,0,19.459V5.332A1.538,1.538,0,0,1,.712,4.018L12.186-3.046a1.347,1.347,0,0,1,1.423,0L25.083,4.018A1.538,1.538,0,0,1,25.8,5.332V19.459a1.538,1.538,0,0,1-.712,1.314L13.609,27.837A1.355,1.355,0,0,1,12.9,28.04ZM12.922.051a1.263,1.263,0,0,0-.662.189L3.343,5.73a1.431,1.431,0,0,0-.662,1.222L2.662,17.885a1.431,1.431,0,0,0,.664,1.226l8.89,5.449a1.253,1.253,0,0,0,1.322,0l8.917-5.492a1.431,1.431,0,0,0,.664-1.221l.019-10.933a1.431,1.431,0,0,0-.664-1.226L13.584.238a1.26,1.26,0,0,0-.66-.188Zm-.048,21.132a1.26,1.26,0,0,1-.662-.189l-.833-.513V14.06l2.961-.8V17.4l3.135-1.924V6.228l2.051,1.265a1.431,1.431,0,0,1,.662,1.223v7.37a1.431,1.431,0,0,1-.662,1.222L13.537,21a1.26,1.26,0,0,1-.662.189ZM8.25,18.557h0L6.226,17.312a1.431,1.431,0,0,1-.662-1.223V8.719A1.431,1.431,0,0,1,6.226,7.5l5.99-3.691a1.253,1.253,0,0,1,1.324,0l.8.492v5.758l-2.96.747V7.182L8.25,9.111v9.446Z"
          transform="translate(0 3.249)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
